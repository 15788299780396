<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.comments.index'}">Back</v-btn>
                    </v-col>
                </v-row>
            </v-card-title> 
            <v-card-text>
                <v-form ref="form">
                    <v-row>
                        <v-col>
                            <v-switch v-model="comment.status"
                                        :label="`Status: ${comment.status ? ' Approve' : ' Ban'}`"
                                        :color="comment.status ? 'teal accent-3' : 'red darken-3'"
                            >
                            </v-switch>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn block color="green" @click="handleSaveComment">Save Changes</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
import Comment from "../../../models/Comment";
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";


export default {
    name: "comments-show",
    components: {AdminDashboardLayout},
    data: function () {
        return {
            comment: {},
            formValid: true,
            labelSwitch:''
        }
    },
    methods: {
        async handleSaveComment() {
            this.formValid = this.$refs.form.validate()
                
            if (this.formValid) {
                const comment = new Comment(this.comment);
                this.comment = await comment.save()
                await this.$router.push({name: 'admin.comments.show', params: {id: this.comment.id}}).catch(()=>{});
            }
        },
        setStatus(status) {
            if(status == 'ban'){
                this.comment.status = 1
            }else if(status == 'approve'){
                this.comment.status == 0
            }else {
                this.comment.status = null;
            }    
        }
    },
    async mounted() {
        const commentId = this.$route.params.id
        if (commentId) {
            this.comment = await Comment.find(commentId);
    
            if(this.comment.status == 0){
                this.labelSwitch = 'Banned'
            }else if (this.comment.status == 1){
                 this.labelSwitch = 'Approved'
            }
        }
    },
}
</script>

<style scoped>

</style>
